@import '~antd/dist/antd.less';

.library {
  .ant-collapse {
    border-left-width: 0;
    border-right-width: 0;

    .ant-collapse-item-active > .ant-collapse-header {
      background-color: #2a2a2a;
      color: @layout-body-background;
    }
  }

  input {
    font-weight: 600;
  }
}

@primary-color: #158D5B;@link-color: #e6a07c;@layout-header-background: #ffffff;@layout-sider-background: #181818;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 2px;@menu-dark-bg: #181818;@layout-trigger-background: rgba(0,0,0,0.2);@layout-header-padding: 0 25px;@layout-footer-padding: 10px 25px;@card-padding-base: 16px;