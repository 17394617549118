@import '~antd/dist/antd.less';

.library.ant-modal {
  @padding: 64px;
  width: 75vw;
  height: calc(100vh - 2 * @padding);
  top: @padding;
  padding: 0;

  .ant-modal-content {
    height: 100%;
    overflow: hidden;

    & > .ant-modal-header {
      position: relative;
      z-index: 1;
      border-bottom-width: 0;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    }

    & > .ant-modal-body {
      background-color: @layout-body-background;
      height: calc(
        100% - @modal-header-title-line-height - 2 *
          @modal-header-padding-vertical
      );
      padding: 0;

      & > .ant-layout {
        height: 100%;

        & > .ant-layout-content {
          overflow-y: scroll;
          padding: @modal-body-padding @modal-body-padding
            calc(@modal-body-padding - @margin-md);

          & > .ant-tabs {
            margin-top: -@modal-body-padding;
            overflow: visible;

            .ant-tabs-content-holder {
              overflow: visible;
            }
          }
        }

        & > .ant-layout-sider {
          background-color: white;
        }
      }
    }
  }
}

@primary-color: #158D5B;@link-color: #e6a07c;@layout-header-background: #ffffff;@layout-sider-background: #181818;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 2px;@menu-dark-bg: #181818;@layout-trigger-background: rgba(0,0,0,0.2);@layout-header-padding: 0 25px;@layout-footer-padding: 10px 25px;@card-padding-base: 16px;